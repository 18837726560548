import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/stage/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/stage/find/${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/stage/${data.id}`, data);
    else return axios.post('/api/stage', data);
  },
  delete: function (id) {
    return axios.delete(`/api/stage/${id}`);
  },
  exportExcel: function (request) {
    return Utils.downloadFile('/api/stage/exportExcel', 'StageExport.xlsx', request);
  },
};
